import request from '@/utils/requestV2'
const qs = require('qs')

// 资源历史工单任务列表(除上刊、下刊、监播、制作)
export function getResourceHistoryList (data) {
  return request({
    url: '/ooh-msp/v2/mspother/getresourcehistorylist',
    method: 'post',
    data: qs.stringify(data)
  })
}
