<template>
  <div>
    <Table stripe :data="historyList" :columns="tableColumns"></Table>
    <div class="paging_style">
      <Page
        size="small"
        :total="total"
        :page-size="query.pageSize"
        show-total
        show-elevator
        :current="query.pageNumber"
        @on-change="handlePageChanged"
      ></Page>
    </div>

    <Modal title="工单反馈附件" v-model="visibleImage" width="800"  :footer-hide="true">
      <img-light-box-component ref="imgLightBox" :imgIndex.sync="imgIndex"></img-light-box-component>
    </Modal>

  </div>
</template>

<script>
import ImgLightBoxComponent from '@/components/common/ImgLightBox'

import { getResourceHistoryList } from '@/api/msp/mspother'
import { formatMspTaskStatusColor } from '@/utils/tagStatus'

export default {
  computed: {
    detailId () {
      return this.$store.state.equipment.detailId
    }
  },
  components: {
    ImgLightBoxComponent
  },
  data () {
    return {
      total: 0,

      imgIndex: 0,
      visibleImage: false,

      query: {
        pageNumber: 1,
        pageSize: 15
      },
      historyList: [],
      tableColumns: [
        {
          title: '作业日期',
          key: 'actionDate'
        },
        {
          title: '任务类型',
          key: 'typeName'
        },
        {
          title: '任务状态',
          key: 'statusName',
          render: (h, params) => {
            return h('Tag', {
              props: {
                color: this.formatStatusTagColor(params.row.status)
              }
            }, params.row.statusName)
          }
        },
        {
          title: '作业完成时间',
          key: 'finishTime'
        },
        {
          title: '作业人员',
          key: 'userName'
        },
        {
          title: '附件反馈',
          key: 'action',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              params.row.attachList.length > 0
                ? h('a', {
                  on: {
                    click: () => {
                      const postData = {
                        startIndex: 0,
                        imgList: params.row.attachList.map(x => x.fileKey)
                      }
                      this.$refs.imgLightBox.init(postData)
                      this.visibleImage = true
                    }
                  }
                }, '详情')
                : h('span', '无')
            ])
          }
        }
      ]
    }
  },
  created () {
    if (this.detailId) {
      this.query.resourceId = this.detailId
      this.initHistory()
    }
  },
  methods: {
    formatStatusTagColor (status) {
      return formatMspTaskStatusColor(status)
    },
    // 初始化资产选项，并默认选中第一条
    initHistory () {
      getResourceHistoryList(this.query).then((res) => {
        this.historyList = res.list
        this.total = res.totalRow
      })
    },

    // 分页
    handlePageChanged (page) {
      this.query.pageNumber = page
      this.initHistory()
    }
  }
}
</script>
